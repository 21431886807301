// stores/auth.store.js
import {defineStore} from 'pinia';
import {ref, watch} from 'vue';
import router from '@/router';
import {useProfileStore} from '@/store/profile.store'; // Import the profile store

export const useAuthStore = defineStore('auth', () => {
  const isAuthenticated = ref(false);
  const isLoggedIn = ref(false);
  const accessToken = ref(null);
  const refreshToken = ref(null);
  const redirectPath = ref(null);
  const isLoading = ref(false);
  const subdomain = ref(null);

  const profileStore = useProfileStore(); // Access the profile store

  function setLoggedIn(payload) {
    isLoggedIn.value = payload;
    isAuthenticated.value = payload;
  }

  function setSubdomain(payload){
    subdomain.value = payload
  }

  function setAccessToken(payload) {
    accessToken.value = payload;
  }

  function setRefreshToken(payload) {
    refreshToken.value = payload;
  }

  function setAuthenticated(payload) {
    isAuthenticated.value = payload;
    isLoggedIn.value = payload;
  }

  function setRedirectPath(path) {
    redirectPath.value = path;
  }

  function clearRedirectPath() {
    redirectPath.value = null;
  }

  function showLoading() {
    isLoading.value = true;
  }

  function hideLoading() {
    isLoading.value = false;
  }

  function clearAuth() {
    isAuthenticated.value = false;
    isLoggedIn.value = false;
    accessToken.value = null;
    refreshToken.value = null;
    redirectPath.value = null;
    subdomain.value = null;
  }

  async function logout() {
    clearAuth();

    setAuthenticated(false);
    setLoggedIn(false);
    setAccessToken(null);
    setRefreshToken(null)
    setSubdomain(null);
    clearRedirectPath();

    // Clear session storage
    sessionStorage.removeItem('auth');
    localStorage.removeItem('auth');
    sessionStorage.clear();

    /*console.log('Auth store cleared:', {
      isAuthenticated: isAuthenticated.value,
      isLoggedIn: isLoggedIn.value,
      subdomain: subdomain.value,
    });*/

    await router.push({name: 'auth-login'});
  }

  // Watch for changes to the access token
  watch(accessToken, async (newToken) => {
    if (newToken && isAuthenticated.value) {
      await profileStore.fetchProfileData();
    }
  });

  return {
    isAuthenticated,
    isLoggedIn,
    accessToken,
    refreshToken,
    redirectPath,
    isLoading,
    subdomain,
    setLoggedIn,
    setAccessToken,
    setRefreshToken,
    setAuthenticated,
    setRedirectPath,
    setSubdomain,
    clearRedirectPath,
    showLoading,
    hideLoading,
    logout
  };
}, {
  persist: {
    enabled: false,
    strategies: [
      {storage: sessionStorage, paths: ['isAuthenticated', 'accessToken']}
    ],
  },
});
