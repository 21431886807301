import axios from 'axios';

import {useAuthStore} from '@/store/auth.store';

import {getEnvVars} from "@/util/env";
import {useRouter} from 'vue-router';
import {getSubdomain} from "@/util/subdomain";

const router = useRouter();


const envVars = getEnvVars();

//console.log('envVars:', envVars);
const apiUrl = envVars.VITE_APP_SERVER_URL; // VITE_APP_SERVER_URL is set in .env file

//console.log('Current Environment Mode:', envVars.MODE, envVars.NODE_ENV);
//console.log('Current Environment API URL:', apiUrl);

const httpService = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json' // Set default Content-Type for all requests
  }
});

// Interceptors for request and response
httpService.interceptors.request.use((config) => {
  //console.log('Current Environment Mode:', process.env.NODE_ENV);
  //console.log('HTTP Request:', config.url, config.method, config.headers, config.data);

  // Check if the URL is absolute and does not match the baseURL
  // This is for external URLs like AWS S3
  const isExternal = config.url.startsWith('http') && !config.url.includes(apiUrl);
  const store = useAuthStore();
  store.showLoading();  // Show loading on request start

  if (!isExternal) {
    let token;
    try {

      token = store.accessToken

      //console.log('token:', token);
    } catch (e) {
      if (envVars.NODE_ENV === 'test' || envVars.MODE === 'test') {
        token = envVars.VITE_APP_TEST_TOKEN;
      } else {
        console.log("Error:", e);
      }
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // Add the X-Subdomain header if a valid subdomain exists
    const subdomain = getSubdomain();
    if (subdomain) {
      config.headers['X-Subdomain'] = subdomain;
    }
  }

  return config;
}, (error) => {
  const store = useAuthStore();
  store.hideLoading();  // Hide loading on request error
  return Promise.reject(error);
});


httpService.interceptors.response.use(
  (response) => {
    const store = useAuthStore();
    store.hideLoading();  // Hide loading on response received
    //NOTE This if left for debugging
    //console.log('Response type:', typeof response.data);
    //console.log('Raw Response:', response);

    /*if (typeof response.data === 'string') {
      try {
        return JSON.parse(response.data);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        throw new Error('Failed to parse JSON response');
      }
    }*/

    return response.data
  },
  (error) => {
    const store = useAuthStore();
    store.hideLoading();  // Hide loading on response error

    if (error.response) {
      const { status } = error.response;

      if (status === 401 && store.isAuthenticated==true) {
        // Unauthorized: Clear session and redirect to login page
        store.logout();
        router.push({
          path: '/auth/login',
          query: {message: "Your Session has expired. Please login again"}
        });
        //router.push({name: 'auth-login'});
      }
      // The request was made, but the server responded with an error
      console.error('Server error:', error.response.status, error.response.data);
      throw error.response?.data;
    } else if (error.request) {
      // The request was made, but no response was received
      console.error('No response received:', error.request);
      throw new Error('No response received from the server');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error in request setup:', error.message);
      throw new Error('Error in request setup');
    }
  }
);

export default httpService;
