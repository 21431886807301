<template>
  <!-- Alert Component -->
  <div class="alert-wrapper">
    <v-alert
      v-model="alert.show"
      :type="alert.type"
      density="compact"
      closable
      @input="alert.show = false"
    >
      {{ alert.message }}
    </v-alert>
  </div>
  <router-view />
</template>

<script setup>

// alert.js
import { reactive, provide } from 'vue';

// Reactive alert state
const alert = reactive({
  show: false,
  message: '',
  type: 'success', // 'success', 'error', 'warning', etc.
});

let timer;

// Core alert functions
function showAlert(message, type = 'success', callback = null) {
  alert.show = true;
  alert.message = message;
  alert.type = type;

  // Clear any existing timer
  clearTimeout(timer);

  timer = setTimeout(() => {
    alert.show = false;
    if (callback) {
      callback();
    }
  }, 5000);
}

function hideAlert() {
  alert.show = false;
  clearTimeout(timer);
}

// Export state and functions
provide('alert', { alert, showAlert, hideAlert });

</script>

<style >
.alert-wrapper {
  position: fixed;
  top: 16px; /* Space from the top */
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  width: auto; /* Or set a fixed width like '400px' */
  max-width: calc(100% - 32px); /* Ensure it does not exceed the viewport */
  margin: 0 auto; /* Centering */
}
</style>
