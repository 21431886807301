// util/subdomain.js

import {useAuthStore} from "@/store/auth.store";

/**
 * Extracts the current subdomain from the URL.
 * @returns {string|null} The subdomain or null if not present.
 */
export function getSubdomain() {
  const hostname = window.location.hostname; // e.g., org.xfitconnect.com
  const domainParts = hostname.split('.');

  // Handle localhost or .localhost subdomains (local testing)
  if (hostname.includes('localhost')) {
    const subdomain = domainParts.length === 2 ? domainParts[0] : null; // e.g., xyz.localhost -> 'xyz'
    return subdomain;
  }

  if (domainParts.length > 2) {
    const subdomain = domainParts[0];
    if (subdomain !== 'test-app') { // Ignore the 'test-app' subdomain
      return subdomain;
    }
  }
  return null; // No subdomain
}

/**
 * Redirects the user to the correct subdomain.
 * @param {string} correctSubdomain - The subdomain to redirect to.
 * @param {string} pathname - The path to append after the subdomain (e.g., /dashboard).
 */
export function redirectToCorrectSubdomain(correctSubdomain, pathname) {
  //console.log("calling redirectToCorrectSubdomain with correctSubdomain", correctSubdomain);
  const currentProtocol = window.location.protocol; // http or https
  const hostname = window.location.hostname; // e.g., org.xfitconnect.com or localhost
  const port = window.location.port; // Port number (e.g., 3000)

  let newUrl;

  // Check if the current hostname already contains the correct subdomain
  const domainParts = hostname.split('.');
  if (domainParts.length > 2 && domainParts[0] === correctSubdomain) {
    // No need to redirect if the subdomain is already correct
    return;
  }

  if (hostname === 'localhost') {
    // Handle localhost with subdomain
    newUrl = `${currentProtocol}//${correctSubdomain}.localhost:${port}${pathname}`;
  } else {
    // Handle production environment
    //When there is a subdomain (e.g., org.xfitconnect.com), slice(1) grabs the domain (xfitconnect.com).
    // When there is no subdomain (e.g., xfitconnect.com), slice(0) grabs the full domain (xfitconnect.com).

    const currentDomain = domainParts.slice(domainParts.length - 2).join('.'); // xfitconnect.com
    newUrl = `${currentProtocol}//${correctSubdomain}.${currentDomain}${pathname}`;
  }

  //console.log("Redirecting to:", newUrl);
  window.location.href = newUrl;
}

/**
 * Checks if the current subdomain matches the required subdomain for a logged-in user.
 * If not, it redirects the user to the correct subdomain and logs them out.
 *
 * @param {Object} store - The auth store containing authentication and subdomain data.
 * @param {Object} to - The target route object.
 * @param {Object} from - The current route object.
 * @returns {boolean} Returns true if a redirection occurred, false otherwise.
 */
export function checkAndRedirectSubdomain(to, from) {
  //console.log("---> inside checkAndRedirectSubdomain", {to:to, from:from});
  const authStore = useAuthStore();
  /*console.log('Auth state on checkAndRedirectSubdomain:', {
    isAuthenticated: authStore.isAuthenticated,
    isLoggedIn: authStore.isLoggedIn,
    subdomain: authStore.subdomain,
  });*/

  const isLoggedIn = authStore.isAuthenticated;
  if (!isLoggedIn) {
    //console.log("--> !loggedIn returning false")
    return false; // No redirection needed
  }

  const subdomain = getSubdomain();
  //console.log("--> inside checkAndRedirectSubdomain subdomain",subdomain)

  const requiredSubdomain = authStore.subdomain; // Get the subdomain from the store

  // Check if the user is logged in and if the subdomain is correct
  //console.log("==> isLoggedIn:", isLoggedIn, "subdomain", subdomain, "requiredSubdomain", requiredSubdomain);
  // If the user is logged out (not authenticated), allow visiting the main domain


  if (isLoggedIn && (subdomain !== requiredSubdomain)) {
    //console.log(`Redirecting to correct subdomain: ${requiredSubdomain}`);

    // Preserve the redirect query if it exists
    const redirectPath = to.fullPath || '/dashboard'; // Redirect to the original target route or dashboard
    const baseRedirectPath = `/auth/login?redirect=${encodeURIComponent(redirectPath)}`;

    // Add a message to indicate why the user needs to log in again
    const message = encodeURIComponent("Please log in again because you logged in from the wrong subdomain.");
    const finalRedirectPath = `${baseRedirectPath}&message=${message}`;

    // Perform the redirection to the correct subdomain
    redirectToCorrectSubdomain(requiredSubdomain, finalRedirectPath);

    // Clear the stored redirect path and return true to indicate redirection
    authStore.clearRedirectPath();
    return true;
  }

  return false; // No redirection needed
}


